import * as React from 'react'
import { Link, graphql } from 'gatsby'
import Layout2 from '../../components/layout2'
import Seo2 from '../../components/seo2'

const IndexPage = () => {
  return (
    <Layout2 pageTitle="Food">
      <p>I love FOOD! Whether it's eating out, cooking, or a Huel, I love it all.</p>      
      </Layout2>
  )
}


export const Head = () => <Seo2 title="Food" />

export default IndexPage